import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./AppContext";
import "../styles/Ui.css";
import "../styles/QuestionPopup.css";
import { postUsersAPI } from "../api/users";



function EndWin() {

  const {
    correctAnswers,
    totalScoreGained,
    totalPossibleScore,
    showEmailInput,
    setShowEmailInput,
    email,
    setEmail,
    resultMessage,
    setResultMessage,
    errorMsg,
    setErrorMsg,
    hasReadConditions,
    setHasReadConditions,
    uniqueTechs,
    getScorePercentage,
    techColors,
    lastWidths,
    animateBars,
    questions,
    incorrectAnswers,
    setIncorrectAnswers,
    setUserAnswer,
    favorite,
    showPopup,
    setShowPopup,

  } = useContext(AppContext);
  const navigate = useNavigate();
  const favoriteTechColor = techColors[uniqueTechs.indexOf(favorite.tech)];
  const [users, setUsers] = useState([]);
  //only to prevent a user from submitting the form multiple times by pressing the Enter key
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleEmailSubmission = async () => {
    if (isSubmitting) {
      // Do nothing if already submitting
      return;
    }
    setIsSubmitting(true);
    if (!isValidEmail(email)) {
      setResultMessage("Invalid email address. Please try again.");
      setIsSubmitting(false); // Reset submitting state
      return;
    }
    const emailExistsInLocal = users.some((user) => user.email === email);
    if (emailExistsInLocal) {
      setResultMessage("Email already exists");
      setIsSubmitting(false); // Reset submitting state
      return;
    }
    try {
      const dataToSend = {
        email,
        point: totalScoreGained,
        done: true,
      };
      if (totalScoreGained === totalPossibleScore) {
        dataToSend.done = true;
      }
      const data = await postUsersAPI(dataToSend); // sending dataToSend instead of just the email
      if (data.error && data.error === "EmailExists") {
        setErrorMsg("Email already exists");
      } else {
        setUsers([...users, data]);
        setEmail(""); // Clear the email input
        setErrorMsg(""); // Clear the error message
        setResultMessage("Email registered successfully!");
        setTimeout(() => {
          setShowPopup(false);
          navigate("/");
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      setErrorMsg("An error occurred. Please try again.");
      setResultMessage("Error registering email. Please try again.");
    } finally {
      setIsSubmitting(false); // Reset submitting state in the finally block
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailKeyPress = (event) => {
    if (event.key === "Enter") {
      handleEmailSubmission();
    }
  };

  const handleUserAnswerChange = (event) => {
    setUserAnswer(event.target.value);
    setResultMessage(""); // Clear the result message when the user's answer changes
  };




  return (
    <>
      {/* Determine the display scenario */}
      {showPopup && (incorrectAnswers >= 3 || correctAnswers >= questions.length) ? (

        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
          <div className="popup object-center text-center text-white flex flex-col items-center my-4">
            <h2 className={`text-4xl font-bold ${correctAnswers >= questions.length ? "text-lime-400" : "text-red-500"}`}>
              {correctAnswers >= questions.length ? "You win!" : "Game Over, you lost!"}
            </h2>

            {
              !showEmailInput &&
              <p className="mx-0 text-xl text-white top-5 right-5">
                {totalScoreGained}/{totalPossibleScore}
              </p>
            }
            {/* Progress Bars */}
            {!showEmailInput &&
              uniqueTechs.map((tech, index) => (
                <div className="flex items-center mt-1 bars" key={tech}>
                  <span
                    className="mx-2 w-20 block truncate "
                    style={{ color: techColors[index % techColors.length] }}
                  >
                    {tech}
                  </span>
                  <div className="relative w-24 h-2 mx-2 overflow-hidden">
                    <div className="relative w-24 h-2 mx-2">
                      <div
                        style={{
                          background: techColors[index % techColors.length],
                          width: animateBars
                            ? `${getScorePercentage(tech)}%`
                            : `${lastWidths[tech] || 0}%`, // Use the last known width or default to 0%
                          height: "100%",
                          transition: animateBars
                            ? "width 1s ease-in-out 1s"
                            : "none", // Added 1s delay here
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}

            {!showEmailInput && (
              <p className="my-4" style={{ color: favoriteTechColor }}>
                {favorite.score > 0
                  ? `Vous êtes spécialiste de ${favorite.tech} avec ${favorite.score} score`
                  : "TU N'AS EU AUCUN BADGE"}
              </p>
            )}

            {showEmailInput && (
              <div className="mb-4">
                <h2 className="text-2xl font-bold mb-4 mt-2 text-lime-400">
                  Tente ta chance et gagne des <br /> trucs informatique super
                  cool!
                </h2>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleEmailKeyPress}
                  placeholder="Mon Email..."
                  className="p-2 text-xl text-black rounded-lg"
                />
                {/* Display error message here */}
                {errorMsg && (
                  <div className="text-red-500 mt-2">{errorMsg}</div>
                )}
                {resultMessage && (
                  <div className="mt-2 text-xl">{resultMessage}</div>
                )}{" "}
                <div className="flex items-center justify-start my-2">
                  <input
                    type="checkbox"
                    id="readConditions"
                    checked={hasReadConditions}
                    onChange={() => setHasReadConditions(!hasReadConditions)}
                    className="mr-2"
                  />
                  <label htmlFor="readConditions" className="my-2 text-sm text-white">
                    J'accepte les conditions générales du concours
                  </label>
                </div>
              </div>
            )}

            <div className="flex justify-between mt-4 w-full">
              <button
                onClick={() => {
                  setShowPopup(false);
                  setIncorrectAnswers(0);
                  navigate("/"); // Navigate to the start page
                  window.location.reload();
                }}
                className="  p-2 mt-4 text-xl bg-blue-500 text-white rounded-lg"
              >
                Start Over
              </button>

              {showEmailInput && (
                <button
                  onClick={handleEmailSubmission}
                  className={` mt-4 p-2 text-xl bg-blue-500 text-white rounded-lg ${!hasReadConditions ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  disabled={!hasReadConditions}
                >
                  Register
                </button>
              )}

              {!showEmailInput && (
                <button
                  onClick={() => setShowEmailInput(true)}
                  className="p-2 mt-4 text-xl bg-blue-500 text-white rounded-lg"
                >
                  Participate
                </button>
              )}
            </div>
          </div>
        </div>

      ) : (
        <div className="popup object-center text-center flex flex-col items-center my-4">
          {resultMessage && <p>{resultMessage}</p>}
        </div>
      )
      }

    </>
  );
}

export default EndWin;
