/////for managing API calls related to user data


const API_URL = window.location.origin+"/users"

export async function getUsersAPI(){
    return fetch(API_URL)
    .then(resp => resp.json())
    .then(data => data)
    .catch(e => console.log(e))
}

export async function postUsersAPI(user){
    return fetch(API_URL,{
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(resp => {
        return resp.json()
    })
    .then(data => data)
    .catch(e => console.log(e))
}

export async function patchUsersAPI(id, done){
    let user = {
        _id: id,
        done: done
    }
    console.log("calling update API!!" + JSON.stringify(user))
    return fetch(API_URL+`/${id}`,{
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(resp => resp.json())
    .then(data => data)
    .catch(e => console.log(e))
}

export async function deleteUsersAPI(id){
    return fetch(API_URL+`/${id}`,{
        method: "DELETE"
    })
    .then(resp => resp.json())
    .then(data => data)
    .catch(e => console.log(e))
}