import React, { useState, useEffect, useContext } from "react";
import "../styles/Main.css";
import { ButterflyIcon } from "./Icons";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import QuestionPopup from "./QuestionPopup";
import { AppContext } from "./AppContext";

export default function Main() {


  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const openModal = (question) => {
    setSelectedQuestion(question);
  };
  const {
    answeredQuestions,
    correctlyAnsweredQuestions,
    handleCorrectAnswer,
    questions,
    correctAnswers,
    setQuestions,
    backgroundImagePath, 
    setBackgroundImagePath

  } = useContext(AppContext);



  useEffect(() => {
    axios
      .get(window.location.origin+"/questions")
      .then((questions) => setQuestions(questions.data))
      .catch((err) => console.log(err));


    axios
    .get(window.location.origin+"/backgrounds")
    .then((response) => {
      // Assuming you want to use the image with id "3"
      const backgroundImageData = response.data.find(bg => bg.id === 3);
      if (backgroundImageData) setBackgroundImagePath(backgroundImageData.imgPath);       
    })
    .catch((err) => console.log(err));



    function preventZoomOnCtrlScroll(event) {
      if (event.ctrlKey) {
        event.preventDefault();
        return false;
      }
    }

    document.addEventListener("keydown", preventZoomOnCtrlScroll);
    document.addEventListener("wheel", preventZoomOnCtrlScroll, {
      passive: false,
    });

    return () => {
      document.removeEventListener("keydown", preventZoomOnCtrlScroll);
      document.removeEventListener("wheel", preventZoomOnCtrlScroll);
    };
  }, []); 

  function hideKeyboard(element) { // Pass the input element from which the keyboard was opened

    element.setAttribute('readonly', 'readonly'); // Force keyboard to hide on input field.
    element.setAttribute('disabled', 'true'); // Force keyboard to hide on textarea field.
    setTimeout(function() {
      element.blur();  //actually close the keyboard
      // Remove readonly attribute after keyboard is hidden.
      element.removeAttribute('readonly');
      element.removeAttribute('disabled');
    }, 100);
  
  }

  useEffect(() => {
    if(selectedQuestion!=null)
    {
      if(/Android/i.test(navigator.userAgent)){
        navigator.virtualKeyboard.hide();
        window.scrollTo(0,0);             
        }
        setTimeout(() => {
          document.body.style.overflowX = '';
        }, 500);
      
    }
    else
    {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.body.style.overflowX = 'scroll';
      }
    }
  }, [selectedQuestion]); 

  useEffect(() => {

      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){

      setTimeout(() => {
        window.scrollTo(700,0); 
    }, 500);
      }
  }, []); 

  return (
    <div className="id='no-scroll' aspect-ratio-wrapper">
      <div className="background-image-container">
        <img
          className="aspect-ratio-image"
          alt="A girl gaming room"
          src={backgroundImagePath}
          width="2000"
        />
        {/* a black gradient at the top of the page */}
        <div className="fixed top-0 left-0 w-full h-60 bg-gradient-to-b from-black text-white flex justify-center items-center text-center"></div>

        {questions &&
          questions.map((question) => {
            const centerX = (question.x + question.r) / 2;
            const centerY = (question.y + question.b) / 2;
            const randomDelay = `${Math.random() * 2}s`;

            return (
              <div
                key={question.id}
                className={`absolute ${selectedQuestion === question ? "hidden-area" : ""
                  }`}
                style={{
                  left: `${question.x}%`,
                  top: `${question.y}%`,
                  width: `${question.r - question.x}%`,
                  height: `${question.b - question.y}%`,
                  background: "rgba(0, 0, 0, 0)",
                  pointerEvents: correctlyAnsweredQuestions.includes(
                    question.id
                  )
                    ? "none"
                    : "auto", // Update this line
                }}
              >
                {/* Use openModal when clicking anywhere within the area */}
                <div
                  onClick={() => openModal(question)}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    className="magnifying-glass-ui"
                    style={{
                      position: "absolute",
                      left: `${centerX}%`,
                      top: `${centerY}%`,
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <ButterflyIcon
                      isHidden={
                        selectedQuestion !== null ||
                        correctlyAnsweredQuestions.includes(question.id)
                      }
                      className="inline mx-1 absolute"
                      style={{
                        animationName: "colorPulse",
                        animationDuration: "8s",
                        animationIterationCount: "infinite",
                        animationDelay: randomDelay,
                      }}
                    />

                    {!answeredQuestions.includes(question.id) &&
                      !correctlyAnsweredQuestions.includes(question.id)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {selectedQuestion && (
        <QuestionPopup
          question={selectedQuestion}
          isOpen={selectedQuestion !== null}
          onClose={() => setSelectedQuestion(null)}
          onCorrectAnswer={handleCorrectAnswer}
        />
      )}
    </div>
  );
}
