import React from 'react';
import { createRoot } from 'react-dom/client'; 
import App from '../src/components/App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // Use createRoot from the correct import
root.render(<App />);

