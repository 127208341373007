import React, { useState, useEffect, useContext } from "react";
import { ButterflyIcon } from "./Icons";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../styles/Ui.css";
import { AppContext } from "./AppContext";

export default function Ui() {

  const {
    incorrectAnswers,
    correctAnswers,
    techTrace,
    scoreTrace,
    setUniqueTechs,
    setAnimateBars,
    setLastWidths,
    totalScoreGained,
    totalPossibleScore,
    animateBars,
    uniqueTechs,
    lastWidths,
    getScorePercentage,
    techColors,
    showEmailInput,
    showPopup, 
    setShowPopup,
  } = useContext(AppContext);
  const [filledTechs, setFilledTechs] = useState([]);
  const [prevCorrectAnswers, setPrevCorrectAnswers] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isMobile, setIsMobile] = useState(false)

  const getRandomColor = () => {
    const letters = "89ABCDEF"; // bright colors only
    let color = "#";
    for (let i = 0; i < 6; i++) {
      // Generate a random hex value from the modified letters string
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const getTechColor = (index) => {
    if (index < techColors.length) {
      return techColors[index];
    } else {
      return getRandomColor();
    }
  };

  const getUniqueTechs = (questions) => {
    return [...new Set(questions.map((question) => question.tech))];
  };
  

  const iconColors = ["white", "white", "white"].map((color, idx) =>
    idx < incorrectAnswers ? "red" : color
  );

  function ScoreAnimation({ score }) {
    return (
      <>
        {score.split("").map((char, index) => (
          <span
            key={index}
            className="char-animation"
            style={{ animationDelay: `${1 + index * 0.25}s` }}
          >
            {char}
          </span>
        ))}
      </>
    );
  }

  useEffect(() => {
    axios
    .get(window.location.origin+"/questions")
    .then((response) => {
      setQuestions(response.data);
      setUniqueTechs(getUniqueTechs(response.data));
   
        if (correctAnswers > prevCorrectAnswers) {
          setShowPopup(true);
          // Start the animation after a slight delay when popup is shown
          setTimeout(() => {
            setAnimateBars(true);
          }, 100); // 100ms delay 
        }
        setPrevCorrectAnswers(correctAnswers);

        if (showPopup) {
          if (totalScoreGained < totalPossibleScore) {
            const timer = setTimeout(() => {
              setShowPopup(false);
              setAnimateBars(false); // Reset
              const latestTech = techTrace[techTrace.length - 1];
              if (!filledTechs.includes(latestTech)) {
                setFilledTechs([...filledTechs, latestTech]);
              }

              // Update the last width for the latest tech
              setLastWidths({
                ...lastWidths,
                [latestTech]: getScorePercentage(latestTech),
              });
            }, 3000);

            // This will clear the timer if the component unmounts before the timer completes.
            return () => clearTimeout(timer);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });
  }, [correctAnswers, incorrectAnswers, showPopup]);

    useEffect(() => {
        const onScroll = () => {
          /*
          let style = { transform: 'translateX(0px)' };

          let itemTranslate = Math.min(0, window.scrollX/3 - 60);
      
            style.transform = 'translateX(' + itemTranslate + 'px)';
            setOffset(style)
            */
          document.querySelector(".overAll").style.transform=`translateX(${window.scrollX}px)`;
        };
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    });

    useEffect(() => {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        setIsMobile( true )
      }
      
  }, []);


  return (
    <>
    <div className="overAll" >
      <ButterflyIcon className="fixed top-5 left-4 fill-lime-400" />

      {/* <ButterflyIcon className="fixed top-5 left-32 fill-white" />
            <ButterflyIcon className="fixed top-5 left-40 fill-white" />
            <ButterflyIcon className="fixed top-5 left-48 fill-white" /> */}
            {!isMobile &&
      <p style={{left:'15rem'}} className="fixed flex text-white text-2xl mx-1 top-5">
        Erreurs
      </p>
        }
      <p className="fixed flex text-white text-2xl mx-1 top-5 left-12">
        <span className="text-lime-500">{correctAnswers}</span>/
        {questions.length}
      </p>

      <p className="fixed flex text-white text-2xl mx-1 top-5 right-5">
        Score {totalScoreGained}
      </p>
      
      <div className="fixed flex flex-col text-white text-base mx-1 top-3 right-40">
      {!isMobile && (
        <>
        {uniqueTechs.map((tech, index) => (
          <div className="flex items-center" key={tech}>
            <span
              className="mx-2 w-20 block truncate "
              style={{ color: getTechColor(index) }}
            >
              {tech}
            </span>

            {/* Progress Bar */}
            <div className="relative w-24 h-2 mx-2 ">
              <div
                style={{
                  background: getTechColor(index),
                  width: `${getScorePercentage(tech)}%`, // You'll need a function to get the score percentage for each tech
                  height: "100%",
                  transition: "width 3s ease-in-out",
                }}
              ></div>
            </div>
          </div>
        ))}
        </>
      )}
        {iconColors.map((color, index) => (
          <ButterflyIcon
            key={index}
            style={{left: 9+index * 2+"rem"}}
            className={`fixed top-5 fill-${color} `}
          />
        ))}
      </div>
    </div>
      {correctAnswers < questions.length && incorrectAnswers < 3 && showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
          <div className=" p-8 rounded-md shadow-xl text-lime-400 ">
            <h2 className="bravo">
              Bravo!
              {scoreTrace.length > 0 ? (
                <>
                  <span className="plus-symbol">+</span>
                  <ScoreAnimation
                    score={scoreTrace[scoreTrace.length - 1].toString()}
                  />
                  <span className="pts-symbol">pts</span>
                </>
              ) : (
                "Aucun score"
              )}
            </h2>

            {/* Progress Bars */}
            {uniqueTechs.map((tech, index) => (
              <div className="flex items-center mt-1 bars" key={tech}>
                <span
                  className="mx-2 w-20 block truncate "
                  style={{ color: techColors[index % techColors.length] }}
                >
                  {tech}
                </span>
                <div className="relative w-24 h-2 mx-2 overflow-hidden">
                  <div className="relative w-24 h-2 mx-2">
                    <div
                      style={{
                        background: techColors[index % techColors.length],
                        width: animateBars
                          ? `${getScorePercentage(tech)}%`
                          : `${lastWidths[tech] || 0}%`, // Use the last known width or default to 0%
                        height: "100%",
                        transition: animateBars
                          ? "width 1s ease-in-out 1s"
                          : "none", // Added 1s delay here
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      </>
  );
}
