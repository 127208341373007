import React, { useState, useEffect } from "react";
import "../styles/App.css";
import Main from "./Main";
import StartPage from "./StartPage";
import Ui from "./Ui";
import EndWin from './EndWin';
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { AppContext } from "./AppContext";



export default function App() {

  const [backgroundImagePath, setBackgroundImagePath] = useState('');
  const [hasReadConditions, setHasReadConditions] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [techTrace, setTechTrace] = useState([]);
  const [scoreTrace, setScoreTrace] = useState([]);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const favorite = getFavoriteTech(techTrace, scoreTrace);
  const [correctlyAnsweredQuestions, setCorrectlyAnsweredQuestions] = useState(
    []
  );
  const totalScoreGained = scoreTrace.reduce(
    (total, score) => total + score,
    0
  );
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [userAnswer, setUserAnswer] = useState("");
  const [lastWidths, setLastWidths] = useState({});
  const [uniqueTechs, setUniqueTechs] = useState([]);
  const [animateBars, setAnimateBars] = useState(false);
  const handleCorrectAnswer = (questionId) => {
    setCorrectlyAnsweredQuestions((prevState) => [...prevState, questionId]);
  };

  useEffect(() => {
    document.title = 'ICT-Girls';
  }, []);

  const handleEmailKeyPress = (event) => {
    console.log("Key pressed:", event.key);
    if (event.key === "Enter") {
      handleEmailSubmission();
    }
  };

  const handleUserAnswerChange = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleEmailSubmission = () => {
    // Validate the email if necessary
    if (!email) {
      setResultMessage("Email cannot be empty");
      return;
    }
    // If you're using regex for validation:
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!regex.test(email)) {
      setResultMessage("Please enter a valid email address");
      return;
    }
    // Send the email to the server or perform other logic
    fetch("/api/email-submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setResultMessage("Email submitted successfully!");
          setEmail(""); // Resetting the email if successful (optional)
        } else {
          setResultMessage("Failed to submit the email. Try again later.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setResultMessage("An error occurred. Try again later.");
      });
  };

  // first use this colors, after them use random bright RGB colors
  const techColors = [
    "cyan",
    "orange",
    "violet",
    "red",
    "blue",
    "green",
    "Yellow",
  ];
  const getScorePercentage = (tech) => {
    const totalPotential = totalPotentialScoreForTech(tech);
    const actual = actualScoreForTech(tech);
    // Get all scores from scoreTrace that correspond to the given tech
    const scoresForTech = techTrace.map((t, index) =>
      t === tech ? scoreTrace[index] : 0
    );
    // Sum the scores for the given tech
    const totalScoreGained = scoresForTech.reduce(
      (acc, score) => acc + score,
      0
    );
    return (actual / totalPotential) * 100; // This will give a percentage
  };
  const totalPotentialScoreForTech = (tech) => {
    return questions
      .filter((q) => q.tech === tech)
      .reduce((acc, q) => acc + q.score, 0);
  };

  const actualScoreForTech = (tech) => {
    let total = 0;
    for (let i = 0; i < techTrace.length; i++) {
      if (techTrace[i] === tech) {
        total += scoreTrace[i];
      }
    }
    return total;
  };
  const [questions, setQuestions] = useState([]);
  const totalPossibleScore = questions.reduce(
    (acc, current) => acc + current.score,
    0
  );

  function getFavoriteTech(techTrace, scoreTrace) {
    const techScores = {};
    //both arrays are of the same length
    for (let i = 0; i < techTrace.length; i++) {
      const tech = techTrace[i];
      const score = scoreTrace[i];

      if (!techScores[tech]) {
        techScores[tech] = 0;
      }
      techScores[tech] += score;
    }
    // Find the tech with the highest total score
    let maxTech = null;
    let maxScore = -1;
    for (const tech in techScores) {
      if (techScores[tech] > maxScore) {
        maxTech = tech;
        maxScore = techScores[tech];
      }
    }
    return {
      tech: maxTech,
      score: maxScore,
    };
  }

  return (
    <>
      <AppContext.Provider
        value={{
          backgroundImagePath, 
          setBackgroundImagePath,
          incorrectAnswers,
          setIncorrectAnswers,
          correctAnswers,
          setCorrectAnswers,
          answeredQuestions,
          setAnsweredQuestions,
          correctlyAnsweredQuestions,
          setCorrectlyAnsweredQuestions,
          handleCorrectAnswer,
          techTrace,
          setTechTrace,
          scoreTrace,
          setScoreTrace,
          totalScoreGained,
          totalPossibleScore,
          showEmailInput,
          setShowEmailInput,
          email,
          setEmail,
          resultMessage,
          setResultMessage,
          handleEmailKeyPress,
          errorMsg,
          setErrorMsg,
          userAnswer,
          setUserAnswer,
          handleEmailSubmission,
          handleEmailKeyPress,
          handleUserAnswerChange,
          lastWidths,
          setLastWidths,
          uniqueTechs,
          setUniqueTechs,
          animateBars,
          setAnimateBars,
          techColors,
          totalPotentialScoreForTech,
          actualScoreForTech,
          questions,
          setQuestions,
          getScorePercentage,
          questions,
          favorite,
          showPopup, 
          setShowPopup,
          hasReadConditions, 
          setHasReadConditions,
        }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<StartPage />} exact />
            <Route
              path="/main"
              element={<><Ui /><Main /> </>}/>
            <Route
              path="/ui"
              element={<><Ui /></>}/>
          </Routes>
          <EndWin />
        </Router>

       
      </AppContext.Provider>

      {/* <UserCRUD /> */}
    </>
  );
}
