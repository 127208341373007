import React, { useState, useEffect, useContext } from "react";
import "../styles/QuestionPopup.css";
import Modal from "react-modal";
import { ButterflyIcon } from "./Icons";
import "../styles/Navigation.css";
import { AppContext } from "./AppContext";
import { postUsersAPI, getUsersAPI } from "../api/users";
import { useNavigate } from "react-router-dom";
import "../styles/Ui.css";

Modal.setAppElement("#root"); // Set the root element for accessibility

export default function QuestionPopup({
  question,
  isOpen,
  onClose,
  onCorrectAnswer,
  getFavoriteTech,
}) {
  const {
    techTrace,
    setTechTrace,
    scoreTrace,
    setScoreTrace,
    setAnsweredQuestions,
    correctAnswers,
    incorrectAnswers,
    setIncorrectAnswers,
    correctlyAnsweredQuestions,
    setCorrectAnswers,
    totalScoreGained,
    showEmailInput,
    setShowEmailInput,
    email,
    setEmail,
    resultMessage,
    setResultMessage,
    errorMsg,
    setErrorMsg,
    userAnswer,
    setUserAnswer,
    animateBars,
    uniqueTechs,
    lastWidths,
    getScorePercentage,
    techColors,
    totalPossibleScore,
    hasReadConditions, 
    setHasReadConditions,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [shake, setShake] = useState(false);
  const [redFlash, setRedFlash] = useState(false);
  const [lastIncorrectAnswer, setLastIncorrectAnswer] = useState(0);
  const [users, setUsers] = useState([]);
 
  const favorite = getFavoriteTech(techTrace, scoreTrace);

  const handleChoiceButtonClick = (choiceKey, questionId) => {
    if (!question) return;
    const isCorrect = choiceKey === question.answer;
    if (isCorrect) {
      setCorrectAnswers((prevCorrectAnswers) => prevCorrectAnswers + 1);
      setAnsweredQuestions((prev) => [...prev, questionId]);
      correctlyAnsweredQuestions.push(question.id);
      onCorrectAnswer(question.id);
      setTechTrace([...techTrace, question.tech]);
      setScoreTrace([...scoreTrace, question.score]);
      onClose();
    } else {
      setIncorrectAnswers(incorrectAnswers + 1);
      setLastIncorrectAnswer(incorrectAnswers + 1); // need it for shake effect
    }
    setUserAnswer(""); // Clear any text input
  };

  const handleInterKeyPress = (event, questionId) => {
    if (!question) return;
    if (event.key === "Enter") {
      const input = event.target.value.toLowerCase();
      const isCorrect = input === question.answer.toLowerCase();
      if (isCorrect) {
        setCorrectAnswers((prevCorrectAnswers) => prevCorrectAnswers + 1);
        setAnsweredQuestions((prev) => [...prev, questionId]);
        correctlyAnsweredQuestions.push(question.id);
        onCorrectAnswer(question.id);
        setTechTrace([...techTrace, question.tech]);
        setScoreTrace([...scoreTrace, question.score]);
        onClose();
      } else {
        setIncorrectAnswers(incorrectAnswers + 1);
        setLastIncorrectAnswer(incorrectAnswers + 1);  // need it for shake effect
      }
      setUserAnswer("");
    }
  };

  const handleEmailSubmission = async () => {
    if (!isValidEmail(email)) {
      setResultMessage("Invalid email address. Please try again.");
      return;
    }
    const emailExistsInLocal = users.some((user) => user.email === email);
    if (emailExistsInLocal) {
      setResultMessage("Email already exists");
      return;
    }
    try {
      const dataToSend = {
        email,
        point: totalScoreGained, // using totalScoreGained here
      };
      const data = await postUsersAPI(dataToSend); // sending dataToSend instead of just the email
      if (data.error && data.error === "EmailExists") {
        setErrorMsg("Email already exists");
      } else {
        setUsers([...users, data]);
        setEmail(""); // Clear the email input
        setErrorMsg(""); // Clear the error message
        setResultMessage("Email registered successfully!");
        setTimeout(() => {
          onClose();
          navigate("/");
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      setErrorMsg("An error occurred. Please try again.");
      setResultMessage("Error registering email. Please try again.");
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailKeyPress = (event) => {
    if (event.key === "Enter") {
      handleEmailSubmission();
    }
  };

  const handleUserAnswerChange = (event) => {
    setUserAnswer(event.target.value);
    setResultMessage(""); // Clear the result message when the user's answer changes
  };

  const handleWrongAnswerClick = (e) => {
    if (incorrectAnswers < 3 && e.target === e.currentTarget) {
      onClose();
      // Reset effects
      setShake(false);
      setRedFlash(false);
    }
  };

  function getFavoriteTech(techTrace, scoreTrace) {
    const techScores = {};
    //both arrays are of the same length
    for (let i = 0; i < techTrace.length; i++) {
      const tech = techTrace[i];
      const score = scoreTrace[i];

      if (!techScores[tech]) {
        techScores[tech] = 0;
      }
      techScores[tech] += score;
    }
    // Find the tech with the highest total score
    let maxTech = null;
    let maxScore = -1;
    for (const tech in techScores) {
      if (techScores[tech] > maxScore) {
        maxTech = tech;
        maxScore = techScores[tech];
      }
    }
    return {
      tech: maxTech,
      score: maxScore,
    };
  }

  useEffect(() => {
    getUsersAPI().then((users) => setUsers(users));

    if (lastIncorrectAnswer > 0) {
      // Initialize with the effect on
      setShake(true);
      setRedFlash(true);

      // Start timers to turn the effect off after 200ms
      const shakeTimeout = setTimeout(() => {
        setShake(false);
      }, 200);

      const redFlashTimeout = setTimeout(() => {
        setRedFlash(false);
      }, 200);

      return () => {
        clearTimeout(shakeTimeout);
        clearTimeout(redFlashTimeout);
      };
    }
  }, [lastIncorrectAnswer, totalScoreGained, totalPossibleScore]);
  const favoriteTechColor = techColors[uniqueTechs.indexOf(favorite.tech)];
  const questionTechColor = techColors[uniqueTechs.indexOf(question.tech)];   


  return (
    <Modal
      isOpen={isOpen }
      onRequestClose={onClose}
      contentLabel="Question Modal"
      className="question-modal"
    >
      <div
        className={`overlay content ${shake ? "shake" : ""} ${
          redFlash ? "red-flash" : ""
        }`}
        onClick={handleWrongAnswerClick}
      >
        <div onClick={(e) => e.stopPropagation()}>
          {/* Determine the display scenario */}
          {incorrectAnswers >= 3 ? (
            <div className="popup object-center text-center flex flex-col items-center my-4">
              {!showEmailInput && (
                <>
                  {/* Display "You Win!" if the totalScoreGained equals totalPossibleScore, otherwise "Game Over, you lost!" */}
                  <h2 className="text-2xl font-bold text-red-500">
                    {correctAnswers >= 8
                      ? "Tu as gagné !"
                      : "Game Over, tu as perdu !"}
                  </h2>
                </>
              )}

              {
                !showEmailInput && 
                <p className="mx-0 text-xl top-5 right-5">
                  {totalScoreGained}/{totalPossibleScore}
                </p>
              } 

              {/* Progress Bars */}
              {!showEmailInput &&
                uniqueTechs.map((tech, index) => (
                  <div className="flex items-center mt-1 bars" key={tech}>
                    <span
                      className="mx-2 w-20 block truncate "
                      style={{ color: techColors[index % techColors.length] }}
                    >
                      {tech}
                    </span>
                    <div className="relative w-24 h-2 mx-2 overflow-hidden">
                      <div className="relative w-24 h-2 mx-2">
                        <div
                          style={{
                            background: techColors[index % techColors.length],
                            width: animateBars
                              ? `${getScorePercentage(tech)}%`
                              : `${lastWidths[tech] || 0}%`, // Use the last known width or default to 0%
                            height: "100%",
                            transition: animateBars
                              ? "width 1s ease-in-out 1s"
                              : "none", // Added 1s delay here
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))} 

               {!showEmailInput && (
                <p className="my-4" style={{ color: favoriteTechColor }}>
                  {favorite.score > 0
                    ? `Vous êtes spécialiste de ${favorite.tech} avec ${favorite.score} score`
                    : "Tu n'as eu aucun badge"}
                </p>
              )} 

              {showEmailInput && (
                <div className="mb-4">
                  <h2 className="text-2xl font-bold mb-4 mt-2 text-lime-400">
                    Tente ta chance et gagne des <br /> trucs informatique super
                    cool!
                  </h2>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleEmailKeyPress}
                    placeholder="Mon Email..."
                    className="p-2 text-xl text-black rounded-lg"
                  />
                  
                  {errorMsg && (
                    <div className="text-red-500 mt-2">{errorMsg}</div>
                  )}
                  {resultMessage && (
                    <div className="mt-2 text-xl">{resultMessage}</div>
                  )}{" "}
                  <div className="flex items-center justify-start my-2">
                    <input
                      type="checkbox"
                      id="readConditions"
                      checked={hasReadConditions}
                      onChange={() => setHasReadConditions(!hasReadConditions)}
                      className="mr-2"
                    />
                    <label htmlFor="readConditions" className="my-2 text-sm">
                      J'accepte les conditions générales du concours
                    </label>
                  </div>
                </div>
              )} 

              <div className="flex justify-between mt-4 w-full">
                <button
                  onClick={() => {
                    onClose();
                    setIncorrectAnswers(0);
                    navigate("/"); // Navigate to the start page
                    window.location.reload();
                  }}
                  className="  p-2 mt-4 text-xl bg-blue-500 text-white rounded-lg"
                >
                  Rejouer
                </button> 

                 {showEmailInput && (
                  <button
                    onClick={handleEmailSubmission}
                    className={` mt-4 p-2 text-xl bg-blue-500 text-white rounded-lg ${
                      !hasReadConditions ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={!hasReadConditions}
                  >
                    S'inscrire
                  </button>
                )} 

                {!showEmailInput && (
                  <button
                    onClick={() => setShowEmailInput(true)}
                    className="p-2 mt-4 text-xl bg-blue-500 text-white rounded-lg"
                  >
                    Participer
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="popup object-center text-center flex flex-col items-center my-4">
              {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && 
                <svg style={{position: 'relative',left: '35%'}} onClick={onClose} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
              </svg>
              }
              <ButterflyIcon className="object-center fill-white mb-3 animate-ping " />              
              <h1
                className="text-1xl text-center"
                style={{ color: questionTechColor }}
              >
                {question.tech}
              </h1>
              <h1 className="text-2xl text-center ">{question.question}</h1>
              <img
                src={question.img}
                alt="Question"
                className="popup-oval-image"
              />

              {question.type === 2 ? (
                <input
                  type="text"
                  value={userAnswer}
                  onChange={handleUserAnswerChange}
                  placeholder="Écrit ta réponse"
                  onKeyDown={handleInterKeyPress}
                  className={`input text-2xl text-lime-400 text-center font-semibold placeholder-lime-400 rounded-lg ${
                    question.type === 2 ? "blinking-green-bold-caret" : ""
                  }`}
                  autoFocus
                />
              ) : (
                <div className="flex flex-col items-center">
                  {Object.keys(question.choix).map((choiceKey) => (
                    <button
                      key={choiceKey}
                      onClick={() => handleChoiceButtonClick(choiceKey)}
                      className="wide-grey-button text-2xl"
                    >
                      {question.choix[choiceKey]}
                    </button>
                  ))}
                </div>
              )}

              {resultMessage && <p>{resultMessage}</p>}
            </div>
          )}
        </div>
      </div>
      
    </Modal>
  );
}
