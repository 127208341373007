import React from "react";
import "../styles/Navigation.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

// i turned the svg icon to function for having hidden variable
function ButterflyIcon(props) {
  const { isHidden, className } = props;
  const hiddenStyle = isHidden ? { display: "none" } : {};
  return (
    <svg
      style={{ ...hiddenStyle, ...props.style }}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="red"
      className={`z-0 ${className}`}
      viewBox="0 0 512 512"
    >
      <path d="M214.37 208H70.57c-31 0-57.37 14.9-67.26 45.33C1.57 258.71 0 265.54 0 272c0 48 96 128 144 128 28.95 0 53.24-10.67 75.72-33.14-8.58 11.05-13.81 23.47-15.72 37.28-6.98 49.25 1.73 83.2 27.23 91.48C255.99 503.66 288 512 304 512c48 0 53.35-109.18 26.33-144.39L214.37 208zm29.29-21.28L288.1 49.96C297.68 20.47 320 0 352 0c5.66 0 12.64.62 18.78 2.61 45.65 14.84 92.07 130.86 77.23 176.51-8.94 27.54-26.59 47.34-54.91 61.77 13.15-4.74 26.59-5.88 40.31-3.42 49 8.58 78.59 27.35 78.59 54.16 0 26.04-1.96 59.05-6.91 74.27-14.83 45.65-120.32 17.01-145.46-19.58l-115.97-159.6z" />
    </svg>
  );
}
ButterflyIcon.propTypes = {
  isHidden: PropTypes.bool,
  className: PropTypes.string,
};

export { ButterflyIcon };
