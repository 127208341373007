import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AppContext } from "./AppContext";
import { useNavigate } from 'react-router-dom';
import { ButterflyIcon } from "./Icons";
//import '../assets/startImgMask.png';
import "../styles/Neige.scss";
import "../styles/StartPage.css";



function StartPage(props) {

    const navigate = useNavigate();
    const [imageData, setImageData] = useState(null);
    const {
        backgroundImagePath, 
        setBackgroundImagePath
      } = useContext(AppContext);
    
    

    useEffect(() => {

        axios
            .get(window.location.origin+"/backgrounds")
            .then((response) => {
                const backgroundImageData = response.data.find(bg => bg.id === 1);
                const backgroundImageMaskData = response.data.find(bg => bg.id === 2); 
                if (backgroundImageData) setBackgroundImagePath(backgroundImageData.imgPath);
                if (backgroundImageMaskData) setImageData(backgroundImageMaskData.imgPath);
            })
            .catch((err) => console.log(err));



        function preventZoomOnCtrlScroll(event) {
            if (event.ctrlKey) {
                event.preventDefault();
                return false;
            }
        }

        document.addEventListener('keydown', preventZoomOnCtrlScroll);
        document.addEventListener('wheel', preventZoomOnCtrlScroll, { passive: false });

        return () => {
            document.removeEventListener('keydown', preventZoomOnCtrlScroll);
            document.removeEventListener('wheel', preventZoomOnCtrlScroll);
        };
    }, [setBackgroundImagePath]);

    const snowFlakes = Array(300).fill(null); // Create 300 snowflakes

    useEffect(() => {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        document.body.style.overflowX = 'scroll';
        setTimeout(() => {
            window.scrollTo(400,0); 
        }, 500);
        
        }
      }, []); 

    console.log(imageData);

    return (
        <div className="  image-container  start-page-btn">
      <div className="image-wrapper ">
        <img src={backgroundImagePath} alt="Start page background" />
        {/* {imageData && <img className="absolute inset-0 w-full h-full object-cover" src={imageData} alt="Start page background" />} */}

        <p
          style={{ top: "3%", left: "41%", fontSize: "4vh", padding: "1vw" }}
          className="absolute fa font-bold py-2 text-5xl text-white rounded-lg ">
          ANNONCE:
        </p>
        <p
          style={{ top: "10%", left: "39%", fontSize: "2vh", padding: "1vw" }}
          className="absolute fa text-3xl text-white rounded-lg ">
          Nous recherchons une ICT-Girl.
        </p>
        <p
          style={{ top: "15%", left: "41%", fontSize: "2vh", padding: "1vw" }}
          className="absolute fa text-3xl text-white rounded-lg ">
          Traite tous les papillons.
        </p>

        <button
          className="font-bold  bg-blue-500 hover:bg-blue-700 text-white z-10 border-b-4  border-blue-700 hover:border-blue-500  "
          onClick={() => {
            navigate("/main");
            window.location.reload();
          }}>
          <span className="mr-2">
            <ButterflyIcon className="fill-white animate-pulse " />
          </span>
          COMMENCER
        </button>

        <div className="mask-container">
          <div className="snow-container">
            {snowFlakes.map((_, index) => (
              <div key={index} className="snow"></div>
            ))}
          </div>
        </div>


      </div>
    </div>
  );
}

export default StartPage;
